exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ashtanga-yoga-tsx": () => import("./../../../src/pages/ashtanga-yoga.tsx" /* webpackChunkName: "component---src-pages-ashtanga-yoga-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kundalini-yoga-tsx": () => import("./../../../src/pages/kundalini-yoga.tsx" /* webpackChunkName: "component---src-pages-kundalini-yoga-tsx" */),
  "component---src-pages-lisa-tsx": () => import("./../../../src/pages/lisa.tsx" /* webpackChunkName: "component---src-pages-lisa-tsx" */),
  "component---src-pages-termine-tsx": () => import("./../../../src/pages/termine.tsx" /* webpackChunkName: "component---src-pages-termine-tsx" */),
  "component---src-pages-weiblichkeit-leben-tsx": () => import("./../../../src/pages/weiblichkeit-leben.tsx" /* webpackChunkName: "component---src-pages-weiblichkeit-leben-tsx" */)
}

